import React, { useState } from 'react'
import './style.css'
import logo from './assets/daiky_White_Galaxy.png'
import sideImg from './assets/LaptopDaiky.png'
import { Divider, Grid, Typography, useMediaQuery, Snackbar, Slide, CircularProgress } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Particles from 'react-particles-js';


function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

const MyComponent = (props) => {
  const [email, setEmail] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [snackbar, setSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarColor, setSnackbarColor] = useState('');

  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const lgOnly = useMediaQuery(theme.breakpoints.only('lg'));
  const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));
  const mdOnly = useMediaQuery(theme.breakpoints.only('md'));



  const showSnackbar = () => {
    setSnackbar(true)
  }
  const hideSnackbar = () => {
    setSnackbar(false)
  }

  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const saveEmail = (email) => {
    if (submitting) return
    if (validateEmail(email)) {
      // Hubspot saving contact
      setSubmitting(true)
      let url = "https://api.hsforms.com/submissions/v3/integration/submit/9084011/7a979c69-3e19-4ee2-ad62-d8f6e9e063bb"
      fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(bundleReqBody())
      }).then((r) => r.json())
        .then(res => {
          if (res.status === "error") throw new Error()
          setSnackbarColor('success')
          setSnackbarMessage('We’ll be in touch soon')
          showSnackbar()
          // this.$emit('showSnackbar', {status: 'success', msg: `You've been added to list! You will receive an email when we launch.`})
        })
        .catch(() => {
          // this.$emit('showSnackbar', {status: 'error', msg: `Your email was not properly saved. Please try again.`})
        })
        .finally(() => { setSubmitting(false) })
    } else {
      setSnackbarColor('error')
      setSnackbarMessage('Invalid email. Please try again')
      showSnackbar()
    }
  }

  const getHutk = () => {
    let hutkFromCookie = document.cookie.split(";").find(x => x.includes("hubspotutk"))
    return hutkFromCookie ? hutkFromCookie.split("=")[1] : null
  }

  const bundleReqBody = () => {
    let obj = {
      fields: [{ name: 'email', value: email }]
    }
    let hutk = getHutk()
    if (hutk) obj['context'] = { hutk }
    return obj
  }


  return (
    <div>
      <Particles
        style={{ minHeight: '100vh', opacity: '0.3', position: 'fixed', width: '100%', top: 0, zIndex: 1 }}
        width={'100%'}
        height={'100vh'}
        params={{
          particles: {
            move: {
              speed: 1
            },
            number: {
              value: 50,
              density: {
                value_area: 710,
                enable: true
              },
            },
            opacity: {
              value: 0.4
            },
            line_linked: {
              color: '#9423DC'
            },
            shape: {
              stroke: {
                width: 8,
                color: '#9423DC'
              }
            }
          },
        }
        } />
      <Grid container justify='center' style={{ padding: '40px 0px' }}>
        <Grid item xs={10} style={{ zIndex: 2 }}>
          <Grid container justify='center'>
            <Grid item xs={11} style={{ display: xsOnly ? 'block' : 'flex', alignItems: 'center' }}>
              <img src={logo} alt="" className='logo' height={xsOnly ? 50 : 70} width={'auto'} />
              {xsOnly ? null :
                <Divider orientation='vertical' style={{ backgroundColor: 'white', margin: '0px 20px', width: 2 }} />
              }
              <div style={{
                paddingTop: xsOnly ? 10 : 0,
                fontSize: xsOnly ? 14 : 18,
                fontFamily: "'Varela Round', sans-serif",
                color: 'white'
              }}>
                AI sales optimizer
                <br />
                + Gamified shopping experience
           </div>
            </Grid>
            <Grid item xs={11}>
              <Grid container justify={mdDown ? 'center' : 'space-between'}>
                <Grid item xs={12} lg={6} style={{ paddingTop: smDown ? 80 : mdOnly ? 160 : 200 }}>
                  <Typography variant={xsOnly ? 'h5' : 'h4'} style={{ color: 'white', fontFamily: "'Poppins', sans-serif", fontWeight: 500 }}>
                    Daiky uses AI to create more engaging shopping experience & to maximize your store's revenue!
                </Typography>
                  <Typography className='subline' variant={xsOnly ? 'subtitle2' : 'h6'}>
                    Our website is on it's way...
              <br />
              Be notified when we launch and get a demo of the app in your mailbox!
            </Typography>
                  <div style={{
                    maxWidth: mdDown ? 650 : 500,
                    marginTop: 35,
                    padding: xsOnly ? '6px 20px' : '10px 24px',
                    position: 'relative',
                    borderRadius: 35,
                    border: '2.5px solid #9423DC',
                  }}>
                    <input
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          saveEmail(email)
                        }
                      }}
                      onChange={(e) => { setEmail(e.target.value) }}
                      type="text"
                      style={{ fontSize: xsOnly ? 13 : 'unset' }}
                      className='input'
                      value={email}
                      placeholder='Email adress' />
                    <div
                      onClick={() => { saveEmail(email) }}
                      style={{
                        fontSize: smDown ? 12 : 'unset',
                        cursor: submitting ? 'unset' : 'pointer',
                        position: "absolute",
                        right: -2,
                        top: -2,
                        bottom: -2,
                        borderRadius: 35,
                        backgroundColor: '#9423DC',
                        color: 'white',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: smDown ? 110 : 150
                      }}>
                      {submitting ?
                        <CircularProgress size={smDown ? 24 : 32} thickness={smDown ? 3.0 : 4.2} style={{ color: 'white' }} />
                        : 'NOTIFY ME'}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={10} sm={8} md={6} lg={4} style={{ paddingTop: lgOnly ? 180 : xsOnly ? 40 : 100 }}>
                  <img src={sideImg} alt="sideImg" className='side-img' />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Snackbar
          style={{ bottom: 15 }}
          TransitionComponent={SlideTransition}
          anchorOrigin={{ vertical: 'bottom', horizontal: "center" }}
          onClose={hideSnackbar}
          open={snackbar}
          autoHideDuration={3000}>
          <Alert elevation={6} variant="filled" severity={snackbarColor}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Grid>
    </div>
  )
}
export default MyComponent;
